import { render, staticRenderFns } from "./CartaPropiedad.vue?vue&type=template&id=5c0e0b46"
import script from "./CartaPropiedad.vue?vue&type=script&lang=js"
export * from "./CartaPropiedad.vue?vue&type=script&lang=js"
import style0 from "./CartaPropiedad.vue?vue&type=style&index=0&id=5c0e0b46&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports