<template>
  <loading-component  v-if="isLoading"/>
  <div class="columns is-multiline" v-else>
    <modal-image
      v-if="imageModal"
      :close="closeModal"
      :image="image"
    />
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard is-flex">
      <div>
        {{ detail.productName }} ({{ detail.name }}) -
        <span class="tag" :class="[
          detail.statusName == 'Pendiente' ? 'is-info' : '',
          detail.statusName == 'Preaprobado' ? 'is-warning' : '',
          detail.statusName == 'Activo' ? 'is-success' : '',
        ]">{{ detail.statusName }}</span>
      </div>
    </div>

    <div class="column is-12 separator"></div>
    <div class="column is-6">
      <div class="is-flex is-flex-direction-column gap">
        <p class="has-text-danger has-text-weight-medium">Carta de sesión de propiedad:</p>
        <a class="button is-outlined" :href="details.pdfUrl" download>Descargar PDF</a>
      </div>
    </div>
    <div class="column is-6">
      <div class="is-flex is-flex-direction-column gap">
        <p class="has-text-danger has-text-weight-medium">Datos bancarios:</p>
        <div>
          <p>
            <strong class="has-text-weight-bold">Banco:</strong>
            <span>{{ details?.nameBank }}</span>
          </p>
          <p>
            <strong class="has-text-weight-bold">CLABE:</strong>
            <span>{{ details?.codeBank }}</span>
          </p>
        </div>
        <p class="has-text-weight-medium">{{ details?.typeDocument === 1 ? 'INE' : 'Pasaporte' }}</p>
        <img
          :src="details?.imageDocument"
          @click="showImageModal(details.imageDocument)"
          :alt="details?.imageDocument" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    ModalImage: () => import('@/components/Products/ModalImage'),
    LoadingComponent: () => import('@/components/ui/Loading')
  },
  data () {
    return {
      imageModal: false,
      image: null,
      details: null,
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['getPropertySession']),
    showImageModal (image) {
      this.image = image
      this.imageModal = true
    },
    closeModal () {
      this.imageModal = false
    }
  },
  async beforeMount () {
    this.isLoading = true

    let response = await this.getPropertySession({ productId: this.detail.id })

    this.details = response

    this.isLoading = false
  }
}
</script>

<style>
.is-flex-direction-column {
  flex-direction: column;
}
.gap {
  gap: 10px;
}
</style>
